@import '~antd/dist/antd.css';

#logo {
  height: 200px;
  background: rgba(255, 255, 255, 0.2) no-repeat center center;
  background-image: url("./logo.png");
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: white;
  font-weight: bolder;
  font-size: large;
  text-transform: uppercase;
  padding-bottom: 30px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
